<template>
  <div>
    <div id="home" class="home-header">
      <div class="home-logo">
        <img class="logo" src="/img/icons/logo.png" />
        <ul>
          <li class="on"><a href="#home">HOME</a></li>
          <li><a href="#service">Service</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </div>
      <div class="home-describe">
        <h2 class="font-bold">Bespoke & High Performant<br>Blockchain Solutions</h2>
        <p>We are a blockchain technology and solution provider company. Our expertise ranges from blockchain infrastructure design to smart contract and internet protocol development. We provide institutional clients with bespoke and high performant blockchain solutions to meet their unique needs.</p>
      </div>
    </div>
    <div id="service" class="home-content">
      <div class="home-service">
        <h2 class="font-bold">SERVICE</h2>
        <a-row class="home-service-row" type="flex" justify="space-around" align="middle">
          <a-col>
            <img src="@/assets/images/SERVICE_1.png"/>
            <p>Blockchain<br>Underlying<br>Platform</p>
          </a-col>
          <a-col>
            <img src="@/assets/images/SERVICE_2.png"/>
            <p>All-in-one<br>Machine</p>
          </a-col>
          <a-col>
            <img src="@/assets/images/SERVICE_3.png"/>
            <p>Open Source<br>Blockchain<br>Platform</p>
          </a-col>
          <a-col>
            <img src="@/assets/images/SERVICE_4.png"/>
            <p>Secure Data<br>Collaboration<br>Platform</p>
          </a-col>
          <a-col>
            <img src="@/assets/images/SERVICE_5.png"/>
            <p>More<br>Blockchain<br>Solutions</p>
          </a-col>
        </a-row>
      </div>
      <div class="home-special">
        <a-row class="home-special-describe">
          <p>Our team has a multidisciplinary background. Many members have held senior positions at Fortune 500 companies. With the relationships we have built with traditional industries and the knowledge we have mastered in the blockchain space, we have been working with institutional partners from fields to build competitive edges by deploying blockchain applications to their existing workflow.</p>
        </a-row>
        <a-row type="flex" justify="space-around" align="middle" class="home-special-row">
          <a-col>
            <p class="font-bold">
              Leading Blockchain Technology
            </p>
          </a-col>
          <a-col>
              <img src="@/assets/images/SERVICE_6.png"/>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-around" align="middle" class="home-special-row">
          <a-col>
            <p class="font-bold">
              Close to Business Senario
            </p>
          </a-col>
          <a-col>
              <img src="@/assets/images/SERVICE_8.png"/>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-around" align="middle" class="home-special-row">
          <a-col>
            <p class="font-bold">
              Global Market Layout
            </p>
          </a-col>
          <a-col>
              <img src="@/assets/images/SERVICE_7.png"/>
          </a-col>
        </a-row>
      </div>
    </div>
    <div id="contact" class="home-footer">
      <div class="home-footer-contact">
        <p class="font-bold">CONTACT</p>
        <p>bd@derivstudio.com</p>
      </div>
      <div class="home-footer-copy">©2024 Deriv Limited | All rights reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home-header{
  background: url("../assets/images/banner-bg.png") no-repeat bottom center #252630;
  padding-bottom: 258px;
  .home-logo{
    width: 1260px;
    margin: 0 auto;
    padding:16px 0;
    display: flex;
    justify-content: space-between;
    .logo{
      width: 64px;
      height: 64px;
    }
    ul{
      display: flex;
      align-items: center;
      li{
        font-size: 24px;
        color: #FFFFFF;
        height: 32px;
        line-height: 32px;
        padding:0 32px;
        &.on{
          a{
            color: #8DED9F;
          }
        }
        a{
          color: #FFFFFF;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
  .home-describe{
    width: 1260px;
    margin: 0 auto;
    h2{
      font-size: 50px;
      color: #8DED9F;
      line-height: 64px;
      padding-top: 114px;
    }
    p{
      width: 920px;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 30px;
      padding-top: 32px;
    }
  }
}
.home-content{
  background: #F0F0F0;
  .home-service{
    padding-bottom: 124px;
    h2{
      text-align: center;
      font-size: 40px;
      color: #000000;
      line-height: 48px;
      padding:72px 0;
    }
    .home-service-row{
      width: 1260px;
      margin: 0 auto;
      .ant-col{
        width: 228px;
        height: 362px;
        background: #FFFFFF;
        padding:32px 20px;
        border-radius: 12px;
        img{
            width: 188px;
            height: 188px;
        }
        p{
          text-align: center;
          font-size: 18px;
          color: #252630;
          line-height: 22px;
          padding-top: 28px;
          font-family: "Montserrat-Semibold";
        }
      }
      
    }
  }
  .home-special{
    background: #FFFFFF;
    padding-bottom: 80px;
    .home-special-describe{
      width: 1206px;
      margin: 0 auto;
      padding-top: 104px;
      padding-bottom: 8px;
      p{
        font-size: 20px;
        color: #252630;
        line-height: 30px;
      }
    }
    .home-special-row{
      width: 1206px;
      margin: 40px auto 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p{
        width: 700px;
        height: 100px;
        border-radius: 12px;
        border: 2px solid #C1C1C1;
        font-size: 26px;
        color: #252630;
        line-height: 100px;
        text-align: center;
      }
      img{
        width: 448px;
        height: 248px;
      }
    }
  }
}
.home-footer{
  background: #252630;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .home-footer-contact{
    text-align: center;
    p:first-child{
      font-size: 34px;
      color: #8DED9F;
      line-height: 40px;
      padding-top: 48px;
    }
     p:last-child{
      font-size: 26px;
      color: #C1C1C1;
      line-height: 32px;
      padding-top: 12px;
     }
  }
  .home-footer-copy{
    font-size: 20px;
    color: #C1C1C1;
    line-height: 24px;
    padding-top: 80px;
  }
}
</style>
